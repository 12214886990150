import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Accessories23.css'
import naturecoasters1 from '../Images/naturecoasters1.webp'
import naturecoasters2 from '../Images/naturecoasters2.webp'
import naturecoasters3 from '../Images/naturecoasters3.webp'
import naturecoasters4 from '../Images/naturecoasters4.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Accessories23 = () => {
   const { t } = useTranslation('global');
  return (
    <div>
        <NavBar className="custom-navbars" />
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Accessories"><h1>{t('turya-accessories')}</h1></Link>  
            </div>
            <div className="container-fluid detailed-turya-main-container">
            <div className="second-inner-turya-detailed-container">
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='naturecoasters1' src={naturecoasters1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='naturecoasters2' src={naturecoasters2} alt="" loading='lazy' />
                  <div className="product-description-nature">
                      <p className='product-description-para-1'><span className='description-heads'>{t('turya-name')}</span>{t('turya-odetonaturecoaster')}</p>
                      <p className='product-description-para-1'> <span className='description-heads'>{t('turya-description')}</span> {t('turya-odetonaturecoaster-para')} 
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-2 naturecoasters3-col3">
                <LazyLoadImage className='naturecoasters3' src={naturecoasters3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                <LazyLoadImage className='naturecoasters4' src={naturecoasters4} alt="" loading='lazy' />
                </div>
            </div>
            </div>
            <div className="product-description-mobile">
            <p className='product-description-para-1'><span className='description-heads'>{t('turya-name')}</span> {t('turya-odetonaturecoaster')}</p>
                      <p className='product-description-para-1'> <span className='description-heads'>{t('turya-description')}</span> {t('turya-odetonaturecoaster-para')}
                      </p>
                  </div>
        </div>
            <div className="arrow-container">
           <Link to="/Accessories22"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/Accessories24"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
    </div>
  )
}

export default Accessories23